.main {
  margin: 0;
  padding: 0;
  min-width: 100vw;
  max-width: 100vw;
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  vertical-align: middle;
}

.content {
  flex: 1;
  margin: 0;
  padding-left: 10px;
  overflow: auto;
}

.menuContent {
  flex: 1;
  margin-right: auto;
  text-align: left;
}

.menuPopup {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100vw;
  overflow: auto;
  max-height: 100vh;
  min-height: 100vh;
  height: 100vh;
}

.menuButton {
  flex: 1;
  margin-left: auto;
  min-width: 10vh; /* vH is intentional, making it square, and the same height as the menu bar.*/
  max-width: 10vh;
  cursor: pointer;
  min-height: 10vh;
  max-height: 10vh;
  user-select: none;
}

.menuButton:hover {
  background-color: lightgrey;
}

.menuClose {
  position: absolute;
  z-index: 999;
  top: 0;
  right: 0;
  background-image: url("close.svg");
}

.menuOpen {
  background-image: url("hamburger.svg");
  position: absolute;
  z-index: 999;
  top: 0;
  right: 0;
}

.menuImage {
  padding: 1vh;
  height: 8vh;
  width: 8vh;
}

.menuItem {
  text-decoration: none;
  color: lightslategrey;
  cursor: pointer;
  align-items: center;
  display: flex;

  padding-left: 7px;
  padding-right: 7px;
}

.menuItem a {
  align-items: center;
  justify-content: center;
}

.menuItem:hover {
  color: black;
}

.hide {
  display: none !important;
}

.allowScroll {
  overflow: auto !important;
}

.hideOverflow {
  overflow: hidden !important;
}

@media (max-width: 810px) {
  .menuClose {
    display: flex;
  }

  .menuOpen {
    display: flex;
  }

  .menuItem {
    min-height: 10vh;
    max-height: 10vh;
    margin: 0px;

    padding-left: 20px;
  }

  .menuItem:hover {
    background-color: lightgrey;
    color: black !important;
  }

  .nonMobileOnly {
    display: none;
  }
}

@media (min-width: 810px) {
  .mobileOnly {
    display: none;
  }

  .menuItem {
    user-select: none;
  }
}

.show {
  display: flex !important;
}
