.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.fullHeight {
  max-height: 100vh;
  max-width: 100vw;
}

a {
  text-decoration: none;
  color: lightslategrey;
}

a:hover {
  color: black;
}

.bgLink {
  cursor: pointer;
}

.bgLink:hover {
  background-color: #dbedf2;
}

.bgLink2 {
  cursor: pointer;
}

.bgLink2:hover {
  background-color: #eaf2f5 !important;
}

li {
  margin-left: 0px;
}

.flexContainer {
  display: flex;
  margin: 0px 0px 0px 0px;
}

.flexItem {
  margin: 5px 0px 0px 0px;
  flex: 1 1 auto;
}

.blueBackground {
  background: lightskyblue;
}

.flexColumn {
  flex-direction: column;
}

.logoImage {
  object-fit: contain;
  cursor: pointer;

  height: 50px;
}

.main {
  flex: 1 1 auto;
  flex-flow: column;
  margin-left: 20px;
  margin-right: 10px;
  overflow: auto;
}

.sfLogin button {
  background-color: transparent !important;

  box-shadow: none !important;

  border: 0px none transparent !important;
  border-radius: 0px !important;

  font-size: 18px !important;

  text-decoration: none;
  color: lightslategrey !important;
}

.sfLogin button:hover {
  color: black !important;
}

.sfLogin svg {
  padding: 5px 0px 0px 0px !important;
}

.sfLogin button div {
  padding: 0px 0px 0px 0px !important;
  width: 26px;
  margin-right: 6px !important;
}

.sfLogin {
  margin-top: auto;
  margin-bottom: auto;
}

.bodyHeader {
  padding: 10px 0px 10px 0px;
  width: 100%;
  position: relative;
  border-bottom: 1px solid #dbedf2;
  z-index: 0;
  font-size: 17pt;
  text-align: left;
}

.bodyPanel1 {
  margin: 20px 0px 10px 10px;
  padding: 0px 20px 0px 0px;

  position: relative;
  flex-flow: column nowrap;
  min-width: 800px;
  height: 100%;
  text-align: left;

  line-height: 25px;

  border-right: 1px solid #dbedf2;
  border-bottom: 1px solid #dbedf2;
}

.adminPanel {
  margin: 20px 0px 10px 10px;
  padding: 0px 20px 0px 0px;
}

.table1 {
  display: grid;
  grid-auto-rows: auto;
  grid-auto-columns: min-content;
}

.twoCol {
  display: grid;
  grid-template-columns: 80px 1fr;
}

.cnp {
  grid-row: 1;
  align-self: left;
}

.c {
  grid-row: 1;
  padding: 0px 5px 0px 5px;
}

.w100 {
  width: 100%;
  min-width: 100%;
}

.wL {
  grid-row: 1fr;
}

.w1 {
  width: 100px;
}

.w2 {
  width: 190px;
}

.w3 {
  width: 240px;
}

.w4 {
  width: 690px;
}

.w5 {
  width: 120px;
}

.w6 {
  width: 60px;
}

.w7 {
  width: 40px;
}

.w8 {
  width: 10px;
}

.w75 {
  width: 68px;
}

.w90 {
  width: 90px;
}

.w30 {
  width: 30px;
}

.w350 {
  width: 350px;
}

.w150 {
  width: 150px;
}

.wSymbolOpt {
  width: 150px;
}

.underline {
  border-bottom: 1px solid #dbedf2;
}

.overline {
  border-top: 1px solid #dbedf2;
}

.ralign {
  text-align: right;
}

.ra {
  text-align: right;
}

.bgc .hover:hover {
  background-color: transparent;
}

.bgc {
  background-color: #dbedf2;
}

.bgc2 {
  background-color: #dbedf2;
}

.bgc3 {
  background-color: #eff4f5;
}

.b {
  font-weight: bold;
}

.border {
  border: 1px solid #dbedf2;
}

.hover:hover {
  background-color: rgb(226, 228, 235);
}

:hover > .bgc2 {
  background-color: transparent !important;
}

.green {
  color: green;
}

.red {
  color: red;
}

.button {
  cursor: pointer;
}
.button:hover {
  background-color: rgb(226, 228, 235);
}

.logoImage {
  padding-left: 5px;
}

@media (min-width: 810px) {
  .logoFont {
    font-size: 148px;
    font-family: "Palatino Linotype", "Book Antiqua", "Palatino", "Likhan", "serif";
  }
}

@media (max-width: 810px) {
  .logoFont {
    font-size: 125px;
    font-family: "Palatino Linotype", "Book Antiqua", "Palatino", "Likhan", "serif";
  }

  .desktopOnly {
    display: none !important;
  }

  .flexContainer {
    flex-direction: column;
  }

  .nav {
    border-bottom: 1px white solid;
  }

  .menuItem {
    padding-left: 0;
    font-size: 20px;
  }

  .nav .menuItem {
    display: none;
  }

  .logoImage {
    height: 10vh;
  }

  .sfLogin button {
    font-size: 25px !important;
  }

  .sfLogin span {
    padding: 0px !important;
    height: 34px;
    margin-top: auto;
    margin-bottom: auto;
  }

  body {
    overflow: auto;
  }

  .main {
    overflow: inherit;
  }

  .bodyPanel {
    border-right: 0;
  }
}

.center {
  text-align: center;
}
